@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
* {
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: baseline;
}
div {
  position:relative;
  z-index: 2;
}

body {
  background-color: #333;
  color: #333;
  font-family: 'Raleway', sans-serif;
  font-weight:400;
}

.quoteContainer {
  border-radius:3px;
  position:relative;
  margin:8% auto auto auto;
  width:450px;
  padding:40px 50px;
  display:table;
  background-color:#fff;
}

.quoteText {
    text-align:center;
    width:450px;
    height:auto;
    clear:both;
    font-weight:500;
    font-size:1.75em;
  }

i{
  font-size:1.0em;
  margin-right: 0.4em;
}

.quoteAuthor {
    width:450px;
    height:auto;
    clear:both;
    padding-top:20px;
    font-size:1em;
    text-align:right;
}

.quoteButtons {
    width: 100%;
    margin: auto;
    display: flex;
  }

.button {
    height: 35px;
    border: none;
    border-radius: 40px;
    color: #fff;
    background-color: #333;
    outline: none;
    font-size: 0.85em;
    padding: 10px 13px 10px 15px;
    margin-top: 0px;
    opacity: 1;
    cursor: pointer;
    }

.socialButton{
    height: 35px;
    border: none;
    color: #111;
    font-size: 0.85em;
    padding: 10px 15px 12px 15px;
    margin-top: 20px;
    opacity: 1;
    cursor: pointer;
}

#tweet-quote #tumblr-quote #facebook-quote #googlePlus-quote{
        float:left;
        padding-top:8px;
        text-align:center;
        font-size:1.2em;
        margin-right:5px;
        height:30px;
        width:40px;
      }

#new-quote {
        float:right;
      }

.footer {
  width:450px;
  text-align:center;
  display:block;
  margin:15px auto 30px auto;
  font-size:0.8em;
  color:#fff;
}

a{
    font-weight:500;
    text-decoration:none;
    color:#111;
}

a:hover{
  text-decoration:none;
}

#quoteMachineAuthor{
  color:#F39C12;
}
